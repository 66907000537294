import React, { useEffect, useMemo, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { graphql, PageProps, Link as GatsbyLink } from "gatsby"
import styled from "@emotion/styled"
import { Layout } from "../components/Layout"

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
`

const Title = styled.h1`
  font-family: PirataOne;
  font-size: 3em;
  text-align: center;
  padding: 1em 0;
  margin-bottom: 0.25em;
  color: sienna;
`

const Entry = styled.article`
  margin: 0px 6px 16px;
  flex: 1;
  min-width: 256px;
  max-width: 512px;
`

const ImageTitle = styled.h4`
  font-family: PirataOne;
  font-size: 1.5em;
  color: rgb(136, 127, 108);
  text-align: right;
  margin-top: -5px;
`

interface HomeProps {
  items: {
    nodes: {
      slug: string
      image: {
        title: string
        file: {
          name: string
        }
      }
    }[]
  }
  site: {
    siteMetadata: {
      title: string
      description: string
    }
  }
}

const INCREMENT = 10

const Home: React.FC<PageProps<HomeProps>> = ({ data }) => {
  const [amount, setAmount] = useState(() => {
    if (typeof window === "undefined") return INCREMENT
    const amount = window.sessionStorage.getItem("amount")
    return amount ? +amount : INCREMENT
  })
  const lastEntryRef = useRef<GatsbyLink<unknown>>(null)
  const entries = useMemo(() => data.items.nodes.slice(0, amount), [amount])

  // Keep track of the amount
  useEffect(() => {
    return () => {
      if (typeof window === "undefined") return
      window.sessionStorage.setItem("amount", `${amount}`)
    }
  }, [amount])

  useEffect(() => {
    if (!lastEntryRef || !lastEntryRef.current) return

    function handleObservation(observerEntries: IntersectionObserverEntry[]) {
      if (observerEntries[0].isIntersecting) {
        // Stop listening to the entries since we are trying to load more
        observer.disconnect()

        // Only try to load more when there are more entries to show
        if (amount < data.items.nodes.length - 1) {
          setAmount(amount + INCREMENT)
        }
      }
    }

    const observer = new IntersectionObserver(handleObservation, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    })

    observer.observe(lastEntryRef.current as any)

    return () => {
      observer.disconnect()
    }
  }, [lastEntryRef.current])

  return (
    <Layout title={data.site.siteMetadata.title} description={data.site.siteMetadata.description}>
      <Helmet>
        <meta name="msvalidate.01" content="EA81901273D061726B3DA9C52F241646" />
      </Helmet>
      <Title>{data.site.siteMetadata.title}</Title>
      <ImageContainer>
        {entries.map((entry, index) => (
          <Entry
            key={entry.slug}
            ref={index === amount - 1 ? (lastEntryRef as any) : null}
            aria-label={entry.image.title}
          >
            <GatsbyLink to={`/${entry.slug}/`}>
              <picture>
                <source srcSet={`images/${entry.image.file.name.replace(".jpeg", ".webp")}`} />
                <img src={`images/${entry.image.file.name}`} alt={entry.image.title} />
              </picture>
            </GatsbyLink>
            <ImageTitle>{entry.image.title}</ImageTitle>
          </Entry>
        ))}
      </ImageContainer>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query ($lang: String!) {
    items: allContentfulImagePost(filter: { node_locale: { eq: $lang } }) {
      nodes {
        slug
        image {
          title
          file {
            name: fileName
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
